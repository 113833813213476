import { Injectable } from "@angular/core"
import { filter, map } from "rxjs/operators"
import { Ooi } from "../models/model"
import { BabylonEngineService, PickMode } from "./babylon-engine.service"
import { AdvancedDynamicTexture } from "@babylonjs/gui/2D/advancedDynamicTexture"
import { UtilityLayerRenderer } from "@babylonjs/core/Rendering/utilityLayerRenderer"
import { PositionGizmo } from "@babylonjs/core/Gizmos/positionGizmo"
import { Vector3 } from "@babylonjs/core/Maths/math.vector"
import { CubicEase, Animation, EasingFunction } from "@babylonjs/core/Animations"
import { Tools } from "@babylonjs/core"
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle"
import { Ellipse, Line, TextBlock } from "@babylonjs/gui/2D/controls"

export interface LabelOptions {
  length: number,
  direction: "up" | "right" | "down" | "left"
  originOffset?: {
    x: number
    y: number
    z: number
  }
}
@Injectable({
  providedIn: "root"
})
export class BabylonjsInteractionService {
  public constructor(
    private babylonEngineService: BabylonEngineService
    ) { }


  private advancedTexture?: AdvancedDynamicTexture
  private utilityLayer?: UtilityLayerRenderer
  private positionGizmo?: PositionGizmo

  public getSinglePickedOoi() {
    return this.babylonEngineService.hit$.pipe(
      filter(pick => pick.type === PickMode.SinglePick),
      map(el => el.picked[0]),
      filter(el => el.isEnabled())
    )
  }

  public moveArcRotateCamera(newTarget: Vector3, newRadius: number, newAlpha: number, newBeta: number, duration: number = 1) {
    const camera = this.babylonEngineService.camera
    const scene = this.babylonEngineService.scene
    if (camera == null || scene == null) { return }
    camera.animations = [
      this.createAnimation("radius", camera.radius, newRadius, duration),
      this.createAnimation("beta", this.simplifyRadians(camera.beta), newBeta, duration),
      this.createAnimation("alpha", this.simplifyRadians(camera.alpha), newAlpha, duration),
      this.createAnimation("target.x", camera.target.x, newTarget.x, duration),
      this.createAnimation("target.y", camera.target.y, newTarget.y, duration),
      this.createAnimation("target.z", camera.target.z, newTarget.z, duration),
    ]

    const frames = camera.animations.flatMap(el => el.getKeys()).map(el => el.frame)

    const startFrame = Math.min(...frames)
    const endFrame = Math.max(...frames)
    scene.beginAnimation(camera, startFrame, endFrame, false)
  }

  public removeAllControls() {
    const transformNodes = this.babylonEngineService.scene.getTransformNodesByTags("origin")
    transformNodes.forEach(el => el.dispose(true))
    const controls = this.advancedTexture?.rootContainer.getDescendants(false)
    controls?.forEach((el) => this.advancedTexture?.removeControl(el))

  }

  public async show3dLabel(ooi: Ooi, text?: string, options: LabelOptions = { length: 250, direction: "up"}) {

    if (!this.advancedTexture) {
      this.advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI")
    }



    const textLabel = text ?? ""

    const origin = ooi.originNode

    if (options.originOffset) {
      origin.position.x += options.originOffset.x
      origin.position.y += options.originOffset.y
      origin.position.z += options.originOffset.z
    }

    const RectLinkOffsetY = options.direction === "up" || options.direction === "down" ? options.length : 0
    const RectLinkOffsetX =  options.direction === "left" || options.direction === "right" ? options.length : 0
    const LineLabelOffsetY = options.direction === "up" || options.direction === "down" ? 20 : 0
    const LineLabelOffsetX = options.direction === "left" || options.direction === "right" ? 0 / 2 : 0
    const LineLinkOffsetY = options.direction === "up" || options.direction === "down" ? 5 : 0
    const LineLinkOffsetX = options.direction === "left" || options.direction === "right" ? 5 : 0

    var rect1 = new Rectangle()
    rect1.width = "0px"
    rect1.height = "40px"
    rect1.color = "#FFF"
    rect1.thickness = 0
    rect1.background = "#001f39bb"
    this.advancedTexture.addControl(rect1)
    rect1.linkWithMesh(origin)
    rect1.linkOffsetY = options.direction === "down" ? RectLinkOffsetY : -RectLinkOffsetY
    rect1.linkOffsetX = options.direction === "right" ? RectLinkOffsetX : -RectLinkOffsetX

    var label = new TextBlock()
    label.text = textLabel
    label.resizeToFit = true
    rect1.addControl(label)

    var target = new Ellipse()
    target.width = "10px"
    target.height = "10px"
    target.background = "#001f39bb"
    this.advancedTexture.addControl(target)
    target.linkWithMesh(origin)

    var line = new Line()
    line.lineWidth = 4
    line.color = "#001f39bb"
    line.y2 = options.direction === "up" ? LineLabelOffsetY : -LineLabelOffsetY
    line.x2 = options.direction === "left" ? LineLabelOffsetX : -LineLabelOffsetX
    line.linkOffsetY = options.direction === "down" ? LineLinkOffsetY : -LineLinkOffsetY
    line.linkOffsetX = options.direction === "right" ? LineLinkOffsetX : -LineLinkOffsetX
    this.advancedTexture.addControl(line)
    line.linkWithMesh(origin)
    line.connectedControl = rect1

    label.onAfterDrawObservable.addOnce(() => {
      console.log("Line x: " + line.x2)
      rect1.width = `${label.widthInPixels + 20}px`
      const widthOffset = (label.widthInPixels + 20) / 2
      if (options.direction === "left" || options.direction === "right") {
        line.x2 = options.direction === "left" ? widthOffset : -widthOffset
      }
    })

  }


    /**
   * Create an animation
   * @param property Describes the property on the object which needs to be animated, e.g. "target.x" or "radius"
   * @param from the start value of the property
   * @param to the end value of the property
   * @param duration for how long should the animation run  (in seconds defaults at 1)
   * @param startAt when should the animation starts in respect to the whole sequence (in seconds defaults to 0)
   * @param fps frames per second for the animation (defaults to 60)
   * @param type what kind of animation
   * @returns
   */
  private createAnimation(property: string, from: number, to: number, duration: number = 1, startAt: number = 0, fps: number = 60, type: number = Animation.ANIMATIONTYPE_FLOAT): Animation {
    const ease = new CubicEase()
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT)

    const animation = Animation.CreateAnimation(
      property,
      type,
      fps,
      ease
    )
    const startFrame = startAt * fps
    const endFrame = (duration + startAt) * fps
    animation.setKeys([
      {
        frame: 0,
        value: from,
      },
      {
        frame: startFrame + 1, // two keys can not start at the same time (produces flicker)
        value: from,
      },
      {
        frame: endFrame,
        value: to,
      },
    ])

    return animation
  }

  public positionEdit(ooi: Ooi) {
    if (this.utilityLayer === undefined || this.positionGizmo === undefined) {
      this.utilityLayer = new UtilityLayerRenderer(this.babylonEngineService.scene)

      // Create the gizmo and attach to the sphere
      this.positionGizmo = new PositionGizmo(this.utilityLayer)
      // Keep the gizmo fixed to world rotation
      this.positionGizmo.updateGizmoRotationToMatchAttachedMesh = false
      this.positionGizmo.updateGizmoPositionToMatchAttachedMesh = true
    }

    this.positionGizmo.attachedNode = ooi.originNode
  }

  private simplifyRadians(radians: number) {
    const simplifiedRadians = radians % (2 * Math.PI)

    return simplifiedRadians < 0
      ? simplifiedRadians + Tools.ToRadians(360)
      : simplifiedRadians
  }
}
