import { Languages } from "../models/languages"
import { translations_animations } from "./translations_animations"
import { translations_trainings } from "./translations_trainings"

export type Translation = Record<Languages, string>
export type Translations = Record<string, Partial<Translation>>

export const translations: Translations = {
  ...translations_animations,
  ...translations_trainings,
  "TRAINING_NOT_YET_IMPLEMENTED.TITLE": {
    "de": "Training noch nicht implementiert",
    "en": "Training not yet implmented"
  },
  "TRAINING_NOT_YET_IMPLEMENTED.TEXT": {
    "de": "Dieses Trainng wurde noch nicht implmentiert, versuchen sie eines der anderen Trainings. :)",
    "en": "Training has not yet been implemented, please try a different one. ;)"
  },
  "WELCOME_TRAINING": {
    "de": "Herzlich willkommen zum webbasierten Training „Switchguard ELS 710 - Überblick”\n\n\nZiel der folgende Lernsequenz ist es, einen Überblick über das Gesamtsystem des Switchguard ELS 710 zu bekommen und die Zusammenhänge der einzelnen Komponenten zu verstehen.\n\nSie können jederzeit zu den Trainingsszenarien springen und diese absolvieren. Dennoch empfehlen wir Ihnen, sich zunächst die Komponenten des Weichenantriebs nacheinander anzusehen.\n\nBevor Sie mit dem Training starten, machen Sie sich mit der Navigation vertraut. Klicken Sie dazu bitte auf \"Navigation lernen\"",
    "en": "A warm welcome to the web-based training \"Basics of the Switchguard ELS710 point machine\".\n\n\nThe aim of the following learning sequence is to get an overview of the overall system of the Switchguard ELS 710 and to understand the interrelationships of the individual components.\n\nIn \"Trainings\" you have the possibility to apply your learned knowledge in szenarios on the 3D model.\n\nBefore you start training, please familiarize yourself with the navigation. To do so, please click the button \"learn navigation\"."
  },
  "WELCOME_TRAINING.AUDIO": {
    "de": "VR_ELS710_01-04_DE.mp3",
    "en": "VR_ELS710_01-04_ENG.mp3"
  },
  "FINISH_TUTORIAL": {
    "de": "Wenn Sie mit der Navigation vertraut sind, können Sie nun das Training beginnen.\n\nIn den Menüs Struktur und Design und Funktionalitäten lernen Sie die theoretischen Grundlagen kennen. Sie können diese in den Trainings dann praktisch anwenden.",
    "en": "If you are familiar with the navigation, you can now start the training.\n\nLearn theoretical basics in the menus \"Structure and Design\" and \"Functionalities\". Apply them practically in \"Trainings\"."
  },
  "FINISH_TUTORIAL.AUDIO": {
    "de": "VR_ELS710_00_DE.mp3",
    "en": "VR_ELS710_00_DE.mp3"
  },
  "TRAINING.START": {
    "de": "Öffnen /Schließen durch Auswählen und Anklicken (Mauszeiger) der Komponente, die Sie kennenlernen möchten",
    "en": "Open / Close to choose the part you would like to get to know."
  },
  "TRAINING.COMPONENT": {
    "de": "Die aktuell ausgewählte Teilkomponente oder Komponentengruppe wird angezeigt.",
    "en": "Currently selected component / component group"
  },
  "TRAINING.INTRO.TITLE": {
    "de": "Einführung Training",
    "en": "Introduction training"
  },
  "TRAINING.INTRO.TEXT": {
    "de": "Sie haben nun die Möglichkeit, das erlernte Wissen in kurzen Trainingsszenarien praxisnah anzuwenden.\n\nAllgemeine Hinweise zu den Trainingssequenzen:\n\n###### Ziel\nVerständnis für das Zusammenwirken der einzelnen Komponenten und die Funktionsweise des Weichenantriebs bekommen\n\n###### Reihenfolge\nFreie Entscheidung in der Reihenfolge der Trainingssequenzen",
    "en": "You now have the opportunity to apply the knowledge you have learned in a practical way in short training scenarios.\n\nGeneral notes on the training sequences:\n\n###### Purpose\nGain an understanding of how the individual components interact and how the point machine works.\n\n###### Order\nFree decision in the order of the training sequences"
  },
  "TRAINING.INTRO.AUDIO": {
    "de": "VR_ELS710_29_DE.mp3",
    "en": "VR_ELS710_29_ENG.mp3"
  },

  "COMPONENTS.INTRO.TITLE": {
    "de": "Einführung Komponenten",
    "en": "Introduction components"
  },
  "COMPONENTS.INTRO.TEXT": {
    "de": "Unter dem Menü “Struktur und Design” können Sie den konstruktiven Aufbau des Weichenantriebes und seine wichtigsten Komponenten kennenlernen.\n\nEin grünes Aufleuchten der Komponente am Modell zeigt an, wo Sie klicken müssen, um weitere Informationen zu erhalten.\n\nAllgemeine Hinweise zu den Komponenten:\n\n###### Ziel\nKennenlernen der einzelnen Komponenten des Weichenantriebs\n\n###### Reihenfolge\nFreie Entscheidung in der Auswahl der Komponenten",
    "en": "Under the \"Components\" menu, you can get to know the design structure of the point machine and its most important components.\n\nA green light up of the component on the model indicates where you must click to get further information.\n\nGeneral notes on the components:\n\n###### Purpose\nGetting to know the individual components of the turnout drive.\n\n###### Order\nFree decision in the selection of the components"
  },
  "COMPONENTS.INTRO.AUDIO": {
    "de": "VR_ELS710_05_DE.mp3",
    "en": "VR_ELS710_05_ENG.mp3"
  },

  "TRAINING.THROW_BAR_EXTEND_RETRACT.MENU_ITEM": {
    "de": "3. Ausfahren und einfahren des Stellschiebers",
    "en": "3. Extending and Retracting the throw bar"

  },
  "TRAINING.CLOSING_SECURING_STATE.MENU_ITEM": {
    "de": "Einheit 3: Verschließen / Sichern der Endlage",
    "en": "Unit 3: Closing / Securing the end state"
  },
  "TRAINING.SUPERVISING_END_STATE": {
    "en": "Unit 4: Supervising end state",
    "de": "Einheit 4: Überwachen der Endlagen"
  },
  "SIEMENS_HIDE": {
    "de": "Ändern der Sichtbarkeit der Teilkomponente oder Komponentengruppe kann hier gewählt werden.",
    "en": "Toggle between visible of a component or component group."
  },
  "AUDIO_PLAY": {
    "de": "Betätigen des Symbols aktiviert das Vorlesen des Textes.",
    "en": "Press the speaker to play a spoken description"
  },
  "AUDIO_STOP": {
    "de": "Betätigen des Symbols beendet das Vorlesen des Textes",
    "en": "When text is being spoken, you can press the stop button to stop the text"
  },
  "SIEMENS_INFORMATION": {
    "de": "Detailierte informationen über die Komponente",
    "en": "Get detailed information about selected component"
  },
  "SIEMENS_CLOSE": {
    "de": "Dialog schließen",
    "en": "Close dialog"
  },
  "TRANSPARENCY_MODE": {
    "de": "Transparenzmodus",
    "en": "Transparency mode"
  },
  "TRANSPARENCY_MODE_DESCRIPTION": {
    "de": "Ermöglicht Ihnen die Szene transparent / X-Ray-Modus zu betrachten",
    "en": "Provides a rough look under the components and is used to display the animations"
  },
  "ROTATE_CAMERA": {
    "de": "Rotiert die Kamera und ermöglicht das Selektieren von Komponenten",
    "en": "Rotate camera & select components"
  },
  "MOVE_CAMERA": {
    "de": "Kamera bewegen",
    "en": "Move camera"
  },
  "ZOOM": {
    "de": "Ein- und rauszoomen",
    "en": "Zoom in and out"
  },
  "HEADER_TUTORIAL": {
    "de": "Wähle ein Symbol für die Funktionen:",
    "en": "Select an icon for the respective functions:"
  },
  "RESET_VIEW": {
    "de": "Ansicht zurücksetzten",
    "en": "Reset View"
  },
  "ANIMATION_TUTORIAL": {
    "de": "Ändern der Geschwindigkeit der Animation",
    "en": "Move to change speed of training animation"
  },
  "SLOW_ANIMATION": {
    "de": "Links = langsamer",
    "en": "Left = Slow animation"
  },
  "FAST_ANIMATION": {
    "de": "Rechts = schneller",
    "en": "Right = Fast animation"
  },
  "LEARNING_SECTION": {
    "de": "Lernbereich zum Kennenlernen der Komponentenbegriffe sowie deren Anordnung und Lage im Weichenantrieb.",
    "en": "Learning Section to get to know components and their placement and functionality in the switch point drive."
  },
  "COMPONENT_LEVEL": {
    "de": "1. Ebene: Hier können Sie eine Komponentengruppe auswählen.\n 2. Ebene: Hier können Sie eine Teilkomponente aus einer Komponentengruppe auswählen.",
    "en": "Level 1: Select assembly in the model\n Level 2: Select a single sub component of an assembly."
  }
}
