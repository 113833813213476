import { A11yModule } from "@angular/cdk/a11y"
import { HttpClient, HttpClientModule } from "@angular/common/http"
import { NgModule } from "@angular/core"
import { BrowserModule } from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { SiLocalizationNgModule } from "@simpl/siemens-brand-localize-with-angular"
import { BsDatepickerModule } from "ngx-bootstrap/datepicker"
import { PopoverModule } from "ngx-bootstrap/popover"

import { SiFooterModule } from "@simpl/siemens-brand-ng/footer"
import { SiBreadcrumbModule } from "@simpl/siemens-brand-ng/breadcrumb"
import { SiFormGroupModule, SI_VALIDATION_ERROR_RESOLVER_MAP } from "@simpl/siemens-brand-ng/form-group"
import { SiDropdownModule } from "@simpl/siemens-brand-ng/dropdown"
import { SiHeaderModule } from "@simpl/siemens-brand-ng/header"
import { ModalModule } from "ngx-bootstrap/modal"

import { AppComponent } from "./app.component"
import { AppRoutingModule } from "./app-routing.module"
import { GettingStartedComponent } from "./pages/getting-started/getting-started.component"
import { HeaderComponent } from "./header/header.component"
import { WelcomeComponent } from "./pages/welcome/welcome.component"
import { InspectorMainComponent } from "./pages/inspector-main/inspector-main.component"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"

import { BsDropdownModule } from "ngx-bootstrap/dropdown"
import { AccordionModule } from "ngx-bootstrap/accordion"

import { NgxBootstrapIconsModule, allIcons  } from "ngx-bootstrap-icons"
import { DialogComponent } from "./components/dialog"
import { DragDropModule } from "@angular/cdk/drag-drop"
import {JoyrideModule} from "ngx-joyride"

import { AmplifyAuthenticatorModule } from "@aws-amplify/ui-angular"
import awsconfig from "../aws-exports"
import { Amplify, Auth } from "aws-amplify"
import { NgxSiMarkdownModule } from "@mo-ti-spa/ngx-si-markdown"
import { TranslationPipe } from "./services/translation.pipe"
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from "@ngx-translate/core"
import {TranslateHttpLoader} from "@ngx-translate/http-loader"
import { TrainingTranslationHandler } from "./utils/TrainingTranslationHandler";
import { DebugDialogComponent } from './components/debug-dialog/debug-dialog.component'


/* Configure Amplify resources */
Amplify.configure(awsconfig)

/* Configuring Auth Module */
const redirectionURL = "https://" + window.location.href.split("//")[1].split("/")[0]
console.log("redirection URL is: " + redirectionURL)

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}

const currentConfig = Auth.configure({
  Auth: {
    region: "eu-central-1",
    userPoolId: awsconfig["aws_user_pools_id"],
    userPoolWebClientId: awsconfig["aws_user_pools_web_client_id"],
    oauth: {
      domain: awsconfig["oauth"]["domain"],
      scope: ["openid", "profile", "email", "aws.cognito.signin.user.admin"],
      redirectSignIn: redirectionURL,
      redirectSignOut: redirectionURL,
      responseType: "code"
    }
  }
})

@NgModule({
  declarations: [
    AppComponent,
    GettingStartedComponent,
    HeaderComponent,
    WelcomeComponent,
    InspectorMainComponent,
    DialogComponent,
    TranslationPipe,
    DebugDialogComponent
  ],
  imports: [
    NgxBootstrapIconsModule.pick(allIcons),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: TrainingTranslationHandler},
      useDefaultLang: true,
      defaultLanguage: "de"
  }),
    DragDropModule,
    BrowserModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    HttpClientModule,
    A11yModule,
    AppRoutingModule,
    SiHeaderModule,
    SiFooterModule,
    SiBreadcrumbModule,
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SiLocalizationNgModule,
    FormsModule,
    ReactiveFormsModule,
    SiFormGroupModule,
    SiDropdownModule,
    AmplifyAuthenticatorModule,
    ModalModule.forRoot(),
    JoyrideModule.forRoot(),
    NgxSiMarkdownModule
  ],
  providers: [{
    provide: SI_VALIDATION_ERROR_RESOLVER_MAP,
    useValue: { required: "Value is required" }
  }],
  bootstrap: [AppComponent]
})
export class AppModule {}
