import { Injectable } from "@angular/core"
import { EMPTY, Observable, Subject } from "rxjs"
import { filter, tap, first, takeWhile, scan, last, share, map } from "rxjs/operators"
import { Model } from "../models/model"
import { interactions } from "../utils/interactions"
import { Action, DialogButtonInteraction, GuidedDialog, GuidedExperience, interpolateString, OrderedTask } from "../utils/utils"
import { BabylonEngineService } from "./babylon-engine.service"
import { BabylonjsInteractionService as BabylonJSInteractionService } from "./babylonjs-interaction.service"
import { DialogService } from "../components/dialog"
import { AnimationService } from "../models"
import { TranslateService } from "@ngx-translate/core"
import { ModelManagementService } from "./model-management.service"
import { Vector3 } from "@babylonjs/core/Maths/math.vector"

interface GuidedInteraction {
  guidedExperience?: GuidedExperience
  step: number
  payload?: any
}

@Injectable({
  providedIn: "root"
})
export class GuidedInteractionServiceService {
  private dialogConfirmMessage = "TRAININGS.CANCEL_TRAINING"

  private guidedInteraction = interactions

  #isInTraining = false

  public get isInTraining() {
    return this.#isInTraining
  }

  private guidedInteractionSource = new Subject<GuidedInteraction>()
  private guidedInteraction$ = this.guidedInteractionSource.asObservable().pipe(
    takeWhile(el => el.payload !== "Done" && this.#isInTraining === true),
    scan((acc, curr) => {
      if (curr.guidedExperience != null && acc.guidedExperience !== curr.guidedExperience) {
        return {
          guidedExperience: curr.guidedExperience,
          step: curr.step
        }
      } else {
        return {
          ...acc,
          ...curr
        }
      }
    }, {} as GuidedInteraction),
    //distinctUntilKeyChanged("step"),
    tap(el => {
      const step = el.guidedExperience?.steps.find(step => el.step === step.id)
      if (step?.actions) {
        this.executeActions(step.actions)
      }
      switch (step?.type) {
        case "GUIDED_DIALOG": {
            this.guidedDialog(step)
          }
          break
        case "ORDERED_TASK": {
          this.orderedTask(step)
          }
          break
        default:
          break
      }
    }),
    share()

  )

  public constructor(
    private dialogService: DialogService,
    private bjsInteractionService: BabylonJSInteractionService,
    private translate: TranslateService,
    private modelManagementService: ModelManagementService) { }


  private async guidedDialog(guidedDialogStep: GuidedDialog) {

    const result = await this.dialogService.show({
      mainContent: [{
        type: "text",
        content: guidedDialogStep.text,
      }],
      playAudioPath: guidedDialogStep.audio,
      title: guidedDialogStep.title,
      isCollapsable: true,
      dialogCloseConfirmMessage: this.dialogConfirmMessage,
      align: "right",
      buttons: (guidedDialogStep.interactions?.filter(el => el.type === "DIALOG_BUTTON_PRESS") as DialogButtonInteraction[])
        .map((el) => ({ text: el.text, payload: el.actions, isClosing: el.isClosing })) ?? []
    }).pipe(filter(el => el.type == "BUTTON_PRESS"), first()).toPromise()
    this.executeActions(result.payload as Action[])
  }


  private async orderedTask(orderedTaskStep: OrderedTask) {

    this.dialogService.show({
      mainContent: [{
        type: "text",
        content: orderedTaskStep.text ?? "",
      }],
      title: orderedTaskStep.title ?? "",
      playAudioPath: orderedTaskStep.audio,
      isCollapsable: true,
      dialogCloseConfirmMessage: this.dialogConfirmMessage,
      align: "right",
      buttons: (orderedTaskStep.interactions?.filter(el => el.type === "DIALOG_BUTTON_PRESS") as DialogButtonInteraction[])
      .map((el) => ({ text: el.text, payload: el.actions, isClosing: el.isClosing })) ?? []
    }).pipe(filter(el => el.type == "BUTTON_PRESS"))
    .subscribe(el => this.executeActions(el.payload as Action[]))

    let orderedIteration = 0
    while (orderedIteration < (orderedTaskStep.selection?.length ?? 0)) {
      if (orderedTaskStep.selection[orderedIteration].actions != null) {
        this.executeActions(orderedTaskStep.selection[orderedIteration].actions!)
      }
      const result = await this.bjsInteractionService.getSinglePickedOoi().pipe(
        map(el => this.modelManagementService.findOOI(el)),
        filter(el => el != null),
        filter(el => !!el), first()).toPromise()
      // make sure your still in training
      if(this.#isInTraining === false) {
        return
      }
      if (result?.id === orderedTaskStep.selection?.[orderedIteration].part) {
        orderedIteration++
        this.modelManagementService.selectOOI(result!, false)
      } else {
        this.modelManagementService.feedback(result!)
        //this.modelManagementService.unselectAllOOI()
        //orderedIteration = 0
      }
    }
    this.executeActions(orderedTaskStep.succuss)
  }

  private async executeActions(actions: Action[]) {
    for(let i = 0; i< actions.length; i++) {
      this.executeAction(actions[i])
    }
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  private async executeAction(action: Action, stateParams?: object) {
    if(action.delay) {
      await this.delay(action.delay)
    }
    switch (action.type) {
      case "HIDE_PART":
        {
          const ooi = this.modelManagementService.findOoiById(action.value)
          ooi?.hide()
        }
        break
      case "SHOW_PART":
        {
          const ooi = this.modelManagementService.findOoiById(action.value)
          ooi?.show()
        }
        break
      case "HIGHLIGHT_PART":
        {
          const ooi = this.modelManagementService.findOoiById(action.value)
          ooi?.highlight(true, action.options)
        }
        break
      case "ARC_CAMERA":
        {
          const {x, y, z, alpha, beta, radius, time } = action
          this.bjsInteractionService.moveArcRotateCamera(new Vector3(x, y, z), radius, alpha, beta, time)
        }
        break
      case "STEP":
        {
          this.guidedInteractionSource.next({
            step: action.value,
            payload: action.payload
          })
        }
        break
      case "ANIMATION":
        {
          if (typeof action.value === "string") {
            this.modelManagementService.playAnimationByName(action.value)
          } else {
            this.modelManagementService.playAnimationByID(action.value)
          }
        }
        break
      case "LOCK":
        {
          this.#isInTraining = action.value
        }
        break
      case "SHOW_DIALOG":
        {
          this.dialogService.show({
            mainContent: [{
              type: "text",
              content: action.text
            }],
            playAudioPath: action.audio ? action.audio : undefined,
            align: action.align,
            title: action.title,
            onClosePayload: action.onLeaveActions,
            buttons: (action.interactions?.filter(el => el.type === "DIALOG_BUTTON_PRESS") as DialogButtonInteraction[])
            ?.map((el) => ({ text: el.text, payload: el.actions, isClosing: el.isClosing })) ?? []
          }).pipe(filter(el => el.type == "BUTTON_PRESS"))
          .subscribe({
            next: (el) => { this.executeActions(el.payload as Action[])},
            complete: () => {
              action.onLeaveActions ? this.executeActions(action.onLeaveActions): undefined
            }
          })
        }
        break
      case "X_RAY_MODE":
        {
          this.modelManagementService.toggleTransparent(action.value)
        }
        break
      case "UNSELECT_ALL":
        {
          this.modelManagementService.unselectAllOOI()
        }
        break
      case "LABEL_PART":
        {
          const ooi = this.modelManagementService.findOoiById(action.value)
          const name = ooi?.getName(this.translate.currentLang)
          const params = { name, ...stateParams }
          const text = this.translate.instant(action.text ?? action.value, params) as string
          const interpolate = interpolateString(text, params)

          ooi ? await this.bjsInteractionService.show3dLabel(ooi, interpolate, action.options) : null
        }
        break
      case "CLEAR_LABEL":
        {
          //const ooi = this.model?.findOoiById(action.value)
          //ooi ? this.bjsInteractionService.show3dLabel(ooi, action.text) : null
        }
        break
      case "CLEAR_ALL_LABEL":
        {
          this.bjsInteractionService.removeAllControls()
        }
        break
      default:
        break
    }
  }

  public startInteraction(name: string) {
    const interaction = this.guidedInteraction[name]

    if (interaction?.type === "SIMPLE") {
      if (interaction?.actions) {
        this.executeActions(interaction.actions)
      }
      return EMPTY
    }

    if (interaction?.onStart) {
      this.executeActions(interaction.onStart)
    }

    return new Observable((observer => {
      const subscription = this.guidedInteraction$.pipe(
        last(),
        tap(_ => {
          this.#isInTraining = false
          console.log("Super Fertig")
        })
      ).subscribe({
        complete() {
          observer.next()
          observer.complete()
        }
      })
      this.guidedInteractionSource.next({
        guidedExperience: interaction,
        step: 1
      })

      // Listen for a close event on any of the dialogs shows in the interaction
      const dialogServiceSubscription = this.dialogService.xrDialogServiceEvents$.pipe(filter(event => event.type === "CLOSE")).subscribe(_ => {
        this.#isInTraining = false
        if (interaction?.onFinished) {
          this.executeActions(interaction.onFinished)
        }
        observer.next()
        observer.complete()
      })

      return () => {
        subscription.unsubscribe()
        dialogServiceSubscription.unsubscribe()
      }
    }))


  }

}
