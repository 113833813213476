import { Color3 } from "@babylonjs/core/Maths/math.color"
import { Interaction } from "./utils"

export const trainings: Record<string,Interaction> = {
  "Ausfahren":  {
    type: "GUIDED",
    onStart: [
      {
        type: "LOCK",
        value: true
      }
    ],
    onFinished: [
      {
        type: "LOCK",
        value: false
      },
      {
        type: "CLEAR_ALL_LABEL"
      }
    ],
    description: "This is a training for Ausfahren.",
    steps: [
      {
        id: 1,
        type: "GUIDED_DIALOG",
        title: "TRAINING.3_6_THROW_BAR_EXTENDING.ORDERED_TASK.TITLE",
        actions: [
          {
            type: "UNSELECT_ALL"
          },
          {
            type: "ARC_CAMERA",
            x: -1.88,
            y: -0.41,
            z: -0.66,
            alpha: 4.31,
            beta: 0.8017,
            radius: 2.55,
            time: 1.8
          },
          {
            type: "CLEAR_ALL_LABEL"
          },
          {
            type: "LABEL_PART",
            value: "99001224813",
            text: "Motor (2)",
            options: {
              length: 150,
              direction: "up"
            }
          },
          {
            type: "LABEL_PART",
            value: "99000093552",
            text: "{{name}} (3)",
            options: {
              length: 150,
              direction: "left",
            }
          },
          {
            type: "LABEL_PART",
            value: "99000062002",
            text: "{{name}} (4)",
            options: {
              length: 150,
              direction: "left"
            }
          },
          {
            type: "LABEL_PART",
            value: "9000072305_-_C25106-A207-C15",
            text: "{{name}} (1) (10)",
            options: {
              length: 110,
              direction: "down"
            }
          },{
            type: "LABEL_PART",
            value: "99000770487",
            text: "{{name}} (8)",
            options: {
              length: 150,
              direction: "up"
            }
          },{
            type: "LABEL_PART",
            value: "99000105531",
            text: "{{name}} (6)",
            options: {
              length: 90,
              direction: "up"
            }
          },{
            type: "LABEL_PART",
            value: "99000080099",
            text: "{{name}} (5)",
            options: {
              length: 160,
              direction: "down"
            }
          },{
            type: "LABEL_PART",
            value: "99000064869",
            text: "{{name}} (7)",
            options: {
              length: 80,
              direction: "down"
            }
          },{
            type: "LABEL_PART",
            value: "99000339688",
            text: "{{name}} (9)",
            options: {
              length: 60,
              direction: "up"
            }
          },{
            type: "HIDE_PART",
            value: "99000518203"
          },
          {
            type: "HIDE_PART",
            value: "99000436070"
          },
          {
            type: "HIDE_PART",
            value: "STANGENABDECK"
          }],
        text: "TRAINING.3_6_THROW_BAR_EXTENDING.STEP1",
        audio: "TRAINING.3_6_THROW_BAR_EXTENDING.STEP1.AUDIO",
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.START",
            actions: [{
              type: "STEP",
              value: 2
            }]
          },
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.PLAY_ANIMATION",
            actions: [{
              type: "ANIMATION",
              value: 0
            },
            {
              type: "STEP",
              value: 1
            }]
          }]

      }, {
        id: 2,
        type: "ORDERED_TASK",
        text: "TRAINING.3_6_THROW_BAR_EXTENDING.STEP2.ORDERED_TASK_INSTRUCTION",
        title: "TRAINING.3_6_THROW_BAR_EXTENDING.ORDERED_TASK.TITLE",
        audio: "TRAINING.3_6_THROW_BAR_EXTENDING.STEP2.ORDERED_TASK_INSTRUCTION.AUDIO",
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.RESET_VIEW",
            actions: [{
              type: "X_RAY_MODE",
              value: false
            },
            {
              type: "HIDE_PART",
              value: "99000518203"
            },
            {
              type: "HIDE_PART",
              value: "99000436070"
            },
            {
              type: "HIDE_PART",
              value: "STANGENABDECK"
            },
            {
              type: "ARC_CAMERA",
              x: -2.42,
              y: -0.50,
              z: -0.53,
              alpha: 4.76,
              beta: 0.7987,
              radius: 1.55,
              time: 1.8
            }]
          }],
        actions: [
          {
            type: "X_RAY_MODE",
            value: false
          },
          {
            type: "HIDE_PART",
            value: "99000518203"
          },
          {
            type: "HIDE_PART",
            value: "99000436070"
          },
          {
            type: "HIDE_PART",
            value: "STANGENABDECK"
          },
          {
            type: "CLEAR_ALL_LABEL"
          },
          {
            type: "ARC_CAMERA",
            x: -2.42,
            y: -0.50,
            z: -0.53,
            alpha: 4.76,
            beta: 0.7987,
            radius: 1.55,
            time: 1.8
          }

        ],
        succuss: [
          {
            type: "ARC_CAMERA",
            x: -1.88,
            y: -0.41,
            z: -0.66,
            alpha: 4.31,
            beta: 0.8017,
            radius: 2.55,
            time: 1.8
          }, {
            type: "STEP",
            value: 3
          }, {
            type: "ANIMATION",
            value: 0
          },
          {
            type: "HIGHLIGHT_PART",
            value: "99001224813",
            options: {
              color: new Color3(0,1,1),
              alpha: 0.1,
              visibility: 1
            }
          }],
        fail: [],
        selection: [
          {
            type: "CLICK_PART",
            part: "9000072305_-_C25106-A207-C15"
          },
          {
            type: "CLICK_PART",
            part: "99001224813"
          },
          {
            type: "CLICK_PART",
            part: "99000093552"
          },
          {
            type: "CLICK_PART",
            part: "99000062002"
          },
          {
            type: "CLICK_PART",
            part: "99000080099"
          },
          {
            type: "CLICK_PART",
            part: "99000105531"
          },
          {
            type: "CLICK_PART",
            part: "99000064869"
          },
          {
            type: "CLICK_PART",
            part: "99000770487"
          },
          {
            type: "CLICK_PART",
            part: "99000339688"
          },
          {
            type: "CLICK_PART",
            part: "9000072305_-_C25106-A207-C15"
          }
        ]
      },
      {
        id: 3,
        type: "GUIDED_DIALOG",
        title: "Title",
        text: "Herzlichen Glückwunsch, alles Korrekt",
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.DONE",
            isClosing: true,
            actions: [{
              type: "STEP",
              value: 2,
              payload: "Done"
            }]
          }]
      }
    ]
  },
  "Einfahren":  {
    type: "GUIDED",
    onStart: [
      {
        type: "LOCK",
        value: true
      }
    ],
    onFinished: [
      {
        type: "LOCK",
        value: false
      },
      {
        type: "CLEAR_ALL_LABEL"
      }
    ],
    description: "This is a training for Einfahren.",
    steps: [
      {
        id: 1,
        type: "GUIDED_DIALOG",
        title: "TRAINING.3_7_EINFAHREN.ORDERED_TASK.TITLE",
        text: "TRAINING.3_7_EINFAHREN.ORDERED_TASK.TEXT",
        audio: "TRAINING.3_7_EINFAHREN.ORDERED_TASK.AUDIO",
        actions: [
          {
            type: "UNSELECT_ALL"
          },
          {
            type: "ARC_CAMERA",
            x: -1.88,
            y: -0.41,
            z: -0.66,
            alpha: 4.31,
            beta: 0.8017,
            radius: 2.55,
            time: 1.8
          },
          {
            type: "CLEAR_ALL_LABEL"
          },
          {
            type: "LABEL_PART",
            value: "99001224813",
            text: "{{name}} (2)",
            options: {
              length: 150,
              direction: "up"
            }
          },
          {
            type: "LABEL_PART",
            value: "99000093552",
            text: "{{name}} (3)",
            options: {
              length: 150,
              direction: "left",
            }
          },
          {
            type: "LABEL_PART",
            value: "99000062002",
            text: "{{name}} (4)",
            options: {
              length: 150,
              direction: "left"
            }
          },
          {
            type: "LABEL_PART",
            value: "9000072305_-_C25106-A207-C14",
            text: "{{name}} (1) (10)",
            options: {
              length: 90,
              direction: "down"
            }
          },{
            type: "LABEL_PART",
            value: "99000770487",
            text: "{{name}} (8)",
            options: {
              length: 150,
              direction: "up"
            }
          },{
            type: "LABEL_PART",
            value: "99000105531",
            text: "{{name}} (6)",
            options: {
              length: 90,
              direction: "up"
            }
          },{
            type: "LABEL_PART",
            value: "99000080099",
            text: "{{name}} (5)",
            options: {
              length: 100,
              direction: "down"
            }
          },{
            type: "LABEL_PART",
            value: "99000064869",
            text: "{{name}} (7)",
            options: {
              length: 80,
              direction: "down"
            }
          },{
            type: "LABEL_PART",
            value: "99000339688",
            text: "{{name}} (9)",
            options: {
              length: 60,
              direction: "up"
            }
          },
          {
            type: "HIDE_PART",
            value: "99000518203"
          },
          {
            type: "HIDE_PART",
            value: "99000436070"
          },
          {
            type: "HIDE_PART",
            value: "STANGENABDECK"
          }],
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.START",
            actions: [{
              type: "STEP",
              value: 2
            }]
          },
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.PLAY_ANIMATION",
            actions: [{
              type: "ANIMATION",
              value: 0
            },
              {
                type: "STEP",
                value: 1
              }]
          }]

      }, {
        id: 2,
        type: "ORDERED_TASK",
        title: "TRAINING.3_7_EINFAHREN.ORDERED_TASK.TITLE",
        text: "TRAINING.3_7_EINFAHREN.STEP2.ORDERED_TASK_INSTRUCTION",
        audio: "TRAINING.3_7_EINFAHREN.STEP2.ORDERED_TASK_INSTRUCTION.AUDIO",
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.RESET_VIEW",
            actions: [{
              type: "X_RAY_MODE",
              value: false
            },
              {
                type: "HIDE_PART",
                value: "99000518203"
              },
              {
                type: "HIDE_PART",
                value: "99000436070"
              },
              {
                type: "ARC_CAMERA",
                x: -2.42,
                y: -0.50,
                z: -0.53,
                alpha: 4.76,
                beta: 0.7987,
                radius: 1.55,
                time: 1.8
              }]
          }],
        actions: [
          {
            type: "X_RAY_MODE",
            value: false
          },
          {
            type: "HIDE_PART",
            value: "99000518203"
          },
          {
            type: "HIDE_PART",
            value: "99000436070"
          },
          {
            type: "HIDE_PART",
            value: "STANGENABDECK"
          },
          {
            type: "CLEAR_ALL_LABEL"
          },
          {
            type: "ARC_CAMERA",
            x: -2.42,
            y: -0.50,
            z: -0.53,
            alpha: 4.76,
            beta: 0.7987,
            radius: 1.55,
            time: 1.8
          }

        ],
        succuss: [
          {
            type: "ARC_CAMERA",
            x: -1.88,
            y: -0.41,
            z: -0.66,
            alpha: 4.31,
            beta: 0.8017,
            radius: 2.55,
            time: 1.8
          }, {
            type: "STEP",
            value: 3
          }, {
            type: "ANIMATION",
            value: 0
          },
          {
            type: "HIGHLIGHT_PART",
            value: "99001224813",
            options: {
              color: new Color3(0,1,1),
              alpha: 0.1,
              visibility: 1
            }
          }],
        fail: [],
        selection: [
          {
            type: "CLICK_PART",
            part: "9000072305_-_C25106-A207-C14"
          },
          {
            type: "CLICK_PART",
            part: "99001224813"
          },
          {
            type: "CLICK_PART",
            part: "99000093552"
          },
          {
            type: "CLICK_PART",
            part: "99000062002"
          },
          {
            type: "CLICK_PART",
            part: "99000080099"
          },
          {
            type: "CLICK_PART",
            part: "99000105531"
          },
          {
            type: "CLICK_PART",
            part: "99000064869"
          },
          {
            type: "CLICK_PART",
            part: "99000770487"
          },
          {
            type: "CLICK_PART",
            part: "99000339688"
          },
          {
            type: "CLICK_PART",
            part: "9000072305_-_C25106-A207-C14"
          }
        ]
      },
      {
        id: 3,
        type: "GUIDED_DIALOG",
        title: "Title",
        text: "Herzlichen Glückwunsch, alles Korrekt",
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.DONE",
            isClosing: true,
            actions: [{
              type: "STEP",
              value: 2,
              payload: "Done"
            }]
          }]
      }
    ]
  },
  "Verschließen":   {
    type: "GUIDED",
    onStart: [
      {
        type: "LOCK",
        value: true
      }
    ],
    onFinished: [
      {
        type: "LOCK",
        value: false
      },
      {
        type: "CLEAR_ALL_LABEL"
      }
    ],
    steps: [
      {
        id: 1,
        type: "GUIDED_DIALOG",
        title: "TRAINING.3_8_VERSCHLIEßEN.ORDERED_TASK.TITLE",
        text: "TRAINING.3_8_VERSCHLIEßEN.ORDERED_TASK.TEXT",
        audio: "TRAINING.3_8_VERSCHLIEßEN.ORDERED_TASK.AUDIO",
        actions: [
          {
            type: "UNSELECT_ALL"
          },
          {type: "ARC_CAMERA",x:-1.55,y:-0.43,z:-0.85,alpha:4.17,beta:0.90,radius:2.66, time: 1.8},
          {
            type: "CLEAR_ALL_LABEL"
          },
          {
            type: "LABEL_PART",
            value: "9000072305_-_C25106-A207-C15",
            text: "{{name}} (1)",
            options: {
              length: 200,
              direction: "down"
            }
          },
          {
            type: "LABEL_PART",
            value: "Tounge.001_primitive0",
            text: "{{name}} (2)",
            options: {
              length: 200,
              direction: "left"
            }
          },
          {
            type: "LABEL_PART",
            value: "99000064869",
            text: "{{name}} (3)",
            options: {
              length: 150,
              direction: "left"
            }
          },
          {
            type: "LABEL_PART",
            value: "9000072305_-_C25106-A207-C14",
            text: "{{name}} (4)",
            options: {
              length: 150,
              direction: "left"
            }
          },
          {
            type: "HIDE_PART",
            value: "99000518203"
          },
          {
            type: "HIDE_PART",
            value: "99000436070"
          },],
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.START",
            actions: [{
              type: "STEP",
              value: 2
            }]
          },
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.PLAY_ANIMATION",
            actions: [{
              type: "ANIMATION",
              value: 0
            },
              {
                type: "STEP",
                value: 1
              }]
          }]

      }, {
        id: 2,
        type: "ORDERED_TASK",
        text: "TRAINING.3_8_VERSCHLIEßEN.STEP2.ORDERED_TASK_INSTRUCTION",
        title: "TRAINING.3_8_VERSCHLIEßEN.ORDERED_TASK.TITLE",
        audio: "TRAINING.3_8_VERSCHLIEßEN.STEP2.ORDERED_TASK_INSTRUCTION.AUDIO",
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.RESET_VIEW",
            actions: [{
              type: "X_RAY_MODE",
              value: false
            },
              {
                type: "HIDE_PART",
                value: "99000518203"
              },
              {
                type: "HIDE_PART",
                value: "99000436070"
              },
              {type: "ARC_CAMERA",x:-1.55,y:-0.43,z:-0.85,alpha:4.17,beta:0.90,radius:2.66, time: 1.8}]
          }],
        actions: [
          {
            type: "X_RAY_MODE",
            value: false
          },
          {
            type: "HIDE_PART",
            value: "99000518203"
          },
          {
            type: "HIDE_PART",
            value: "99000436070"
          },
          {
            type: "CLEAR_ALL_LABEL"
          },
          {type: "ARC_CAMERA",x:-1.55,y:-0.43,z:-0.85,alpha:4.17,beta:0.90,radius:2.66, time: 1.8}

        ],
        succuss: [
          {
            type: "ARC_CAMERA",
            x: -1.88,
            y: -0.41,
            z: -0.66,
            alpha: 4.31,
            beta: 0.8017,
            radius: 2.55,
            time: 1.8
          }, {
            type: "STEP",
            value: 3
          }, {
            type: "ANIMATION",
            value: 0
          },
          {
            type: "HIGHLIGHT_PART",
            value: "99001224813",
            options: {
              color: new Color3(0,1,1),
              alpha: 0.1,
              visibility: 1
            }
          }],
        fail: [],
        selection: [
          {
            type: "CLICK_PART",
            part: "9000072305_-_C25106-A207-C15"
          },
          {
            type: "CLICK_PART",
            part: "Tounge.001_primitive0"
          },
          {
            type: "CLICK_PART",
            part: "99000064869"
          },
          {
            type: "CLICK_PART",
            part: "9000072305_-_C25106-A207-C14"
          },
        ]
      },
      {
        id: 3,
        type: "GUIDED_DIALOG",
        title: "Title",
        text: "Herzlichen Glückwunsch, alles Korrekt",
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.DONE",
            isClosing: true,
            actions: [{
              type: "STEP",
              value: 2,
              payload: "Done"
            }]
          }]
      }
    ]
  },
  "Uberwachen":  {
    type: "GUIDED",
    onStart: [
      {
        type: "LOCK",
        value: true
      }
    ],
    onFinished: [
      {
        type: "LOCK",
        value: false
      },
      {
        type: "CLEAR_ALL_LABEL"
      }
    ],
    steps: [
      {
        id: 1,
        type: "GUIDED_DIALOG",
        title: "TRAINING.3_9_UBERWACHEN.ORDERED_TASK.TITLE",
        actions: [
          {
            type: "UNSELECT_ALL"
          },
          {type: "ARC_CAMERA", x:-2.29,y:-0.60,z:-0.74,alpha:4.52,beta:0.59,radius:1.01, time: 1.8},
          {
            type: "CLEAR_ALL_LABEL"
          },
          {
            type: "LABEL_PART",
            value: "9000072305_-_C25106-A207-C15",
            text: "{{name}} (1)",
            options: {
              length: 200,
              direction: "down"
            }
          },
          {
            type: "LABEL_PART",
            value: "99001123024",
            text: "{{name}} (2)",
            options: {
              length: 150,
              direction: "right"
            }
          },
          {
            type: "LABEL_PART",
            value: "9000072305_-_C25106-A207-C14",
            text: "{{name}} (3)",
            options: {
              length: 90,
              direction: "down"
            }
          },
          {
            type: "HIDE_PART",
            value: "99000518203"
          },
          {
            type: "HIDE_PART",
            value: "99000436070"
          },
          {
            type: "HIDE_PART",
            value: "99000104671"
          }],
        text: "TRAINING.3_9_UBERWACHEN.ORDERED_TASK.TEXT",
        audio: "TRAINING.3_9_UBERWACHEN.ORDERED_TASK.AUDIO",
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.START",
            actions: [{
              type: "STEP",
              value: 2
            }]
          },
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.PLAY_ANIMATION",
            actions: [{
              type: "ANIMATION",
              value: 0
            },
            {
              type: "HIGHLIGHT_PART",
              value: "99001123024",
              options: {
                color: new Color3(0,1,1),
                alpha: 0.1,
                visibility: 1
              }
            },
              {
                type: "STEP",
                value: 1
              }]
          }]

      }, {
        id: 2,
        type: "ORDERED_TASK",
        text: "TRAINING.3_9_UBERWACHEN.STEP2.ORDERED_TASK_INSTRUCTION",
        title: "TRAINING.3_9_UBERWACHEN.STEP2.ORDERED_TASK.TITLE",
        audio: "TRAINING.3_9_UBERWACHEN.STEP2.ORDERED_TASK_INSTRUCTION.AUDIO",
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.RESET_VIEW",
            actions: [{
              type: "X_RAY_MODE",
              value: false
            },
              {
                type: "HIDE_PART",
                value: "99000518203"
              },
              {
                type: "HIDE_PART",
                value: "99000436070"
              },
              {
                type: "HIDE_PART",
                value: "99000104671"
              },
              {type: "ARC_CAMERA", x:-2.29,y:-0.60,z:-0.74,alpha:4.52,beta:0.59,radius:1.01, time: 1.8},]
          }],
        actions: [
          {
            type: "X_RAY_MODE",
            value: false
          },
          {
            type: "HIDE_PART",
            value: "99000518203"
          },
          {
            type: "HIDE_PART",
            value: "99000436070"
          },
          {
            type: "HIDE_PART",
            value: "99000104671"
          },
          {
            type: "CLEAR_ALL_LABEL"
          },
          {type: "ARC_CAMERA", x:-2.29,y:-0.60,z:-0.74,alpha:4.52,beta:0.59,radius:1.01, time: 1.8},

        ],
        succuss: [
          {type: "ARC_CAMERA", x:-2.29,y:-0.60,z:-0.74,alpha:4.52,beta:0.59,radius:1.21, time: 1.8}, {
            type: "STEP",
            value: 3
          }, {
            type: "ANIMATION",
            value: 0
          },
          {
            type: "HIGHLIGHT_PART",
            value: "99001224813",
            options: {
              color: new Color3(0,1,1),
              alpha: 0.1,
              visibility: 1
            }
          }],
        fail: [],
        selection: [
          {
            type: "CLICK_PART",
            part: "9000072305_-_C25106-A207-C15"
          },
          {
            type: "CLICK_PART",
            part: "99001123024"
          },
          {
            type: "CLICK_PART",
            part: "9000072305_-_C25106-A207-C14"
          }
        ]
      },
      {
        id: 3,
        type: "GUIDED_DIALOG",
        title: "Title",
        text: "Herzlichen Glückwunsch, alles Korrekt",
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.DONE",
            isClosing: true,
            actions: [{
              type: "STEP",
              value: 2,
              payload: "Done"
            }]
          }]
      }
    ]
  }
}
