// File for utils

import { AnimationGroup } from "@babylonjs/core/Animations/animationGroup"
import { OverlayOptions } from "../models/model"
import { LabelOptions } from "../services/babylonjs-interaction.service"

// Temporary information about the training

export interface MenuButton {
  name: string
  type: "GUIDED_INTERACTION"
  value: string
}



export type Interaction = GuidedExperience | SimpleExperience
export interface GuidedExperience {
  type: "GUIDED"
  onStart?: Action[]
  onFinished?: Action[]
  description?: string
  steps: Step[]
}
export interface SimpleExperience {
  type: "SIMPLE"
  actions: Action[]
}

interface BaseStep {
  id: number,
  actions?: Action[]
}

export type Step = GuidedDialog | OrderedTask

export interface GuidedDialog extends BaseStep {
  type: "GUIDED_DIALOG"
  title: string
  text: string
  audio?: string,
  interactions?: DialogInteraction[]
}

export interface OrderedTask extends BaseStep {
  type: "ORDERED_TASK"
  title: string
  text: string
  audio?: string,
  interactions?: DialogInteraction[]
  selection: Selection[],
  succuss: Action[],
  fail: Action[]
}

interface ActionType {
  type: string,
  delay?: number
}

export type Action = ArcCameraAction | HidePart | ShowPart | HighlightPart |
  AnimationPlayAction | StepAction | LockAction | ShowDialog | XRayMode | UnselectAll |
  LabelPart | ClearLabel | ClearAllLabels

export interface ArcCameraAction extends ActionType {
  type: "ARC_CAMERA"
  x: number,
  y: number,
  z: number,
  alpha: number,
  beta: number,
  radius: number,
  time: number
}

export interface HidePart extends ActionType {
  type: "HIDE_PART"
  value: string
}

export interface HighlightPart extends ActionType {
  type: "HIGHLIGHT_PART",
  value: string,
  options?: OverlayOptions
}

export interface XRayMode extends ActionType {
  type: "X_RAY_MODE"
  value: boolean
}

export interface LabelPart extends ActionType {
  type: "LABEL_PART",
  value: string
  text?: string,
  options?: LabelOptions
}

export interface ClearLabel extends ActionType {
  type: "CLEAR_LABEL",
  value: string
}

export interface ClearAllLabels extends ActionType {
  type: "CLEAR_ALL_LABEL"
}

export interface UnselectAll extends ActionType {
  type: "UNSELECT_ALL"
}

export interface ShowPart extends ActionType {
  type: "SHOW_PART"
  value: string
}

export interface ShowDialog extends ActionType {
  type: "SHOW_DIALOG"
  title: string
  text: string,
  audio?: string
  align?: "left" | "center" | "right"
  interactions?: DialogInteraction[]
  onLeaveActions?: Action[]
}

export interface StepAction extends ActionType {
  type: "STEP"
  value: number
  payload?: string
}

export interface LockAction extends ActionType {
  type: "LOCK"
  value: boolean
}

export interface AnimationPlayAction extends ActionType {
  type: "ANIMATION"
  action?: "PLAY" | "STOP"
  value: string | number,
  options?: {
    startTime: number,
    endTime: number
  }
}

export type DialogInteraction = DialogButtonInteraction | DialogEventInteraction

export interface DialogButtonInteraction {
  type: "DIALOG_BUTTON_PRESS",
  text: string,
  isClosing?: boolean
  actions: Action[]
}

export interface DialogEventInteraction {
  type: "DIALOG_EVENT",
  event: string,
  isClosing?: boolean
  actions: Action[]
}

export interface Selection {
  type: "CLICK_PART",
  part: string,
  actions?: Action[]
}

// [Do internal stuff](#test "This plays the animation")

/**
 * Allows to play get a relative time of an animation group from 0 to 1
 * @param animationGroup AnimationGroup to get relative AnimationTime
 * @param end from 0..1
 * @param start from 0..1 defaults at 0
 * @returns tuple for first and last frame
 */
export const relativeAnimationTime = (animationGroup: AnimationGroup, end: number, start: number = 0) => {
  const { from, to } = animationGroup
  const total = to - from
  return {
    firstFrame: from + (total * start),
    lastFrame: from + (total * end)
  }
}

/**
 *
 * @param animationGroup animation group to use
 * @param time time in seconds
 * @returns
 */
export const groupFrameToTimeConversion = (animationGroup: AnimationGroup, time: number) => {
  if (animationGroup.targetedAnimations.length === 0) {
    throw new Error("Animation group needs at least one targetedAnimation")

  }
  const fps = animationGroup.targetedAnimations[0].animation.framePerSecond
  return fps * time
}


export const interpolateString = (expr: string, params: any) => {
  const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g
  if (!params) {
      return expr
  }
  // eslint-disable-next-line arrow-body-style
  return expr.replace(templateMatcher, (substring, b) => {
      return params[b] ?? substring
  })
}

// from: https://stackoverflow.com/a/43849204 by Adriano Spadoni
export const resolvePath = (object: any, path: string, defaultValue: string) => path
  .split(".")
  .reduce((o, p) => o ? o[p] : defaultValue, object)

