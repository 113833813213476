<div class="wrapper">
  <canvas #canvasRef id="canvasRef" [ngClass]="{ 'blur' : doBlur}">
  </canvas>
  <app-debug-dialog *ngIf="this.isShowDebug" [ooi]="this.selectedItem"></app-debug-dialog>
</div>

<div *ngIf="(isArMode$ | async) === false" class="app-header" (click)="headerClick()">

  <ng-container *ngIf="menuOois$ | async as menuOois">
  <div class="header-left">
    <div class="btn-group" [insideClick]="true" style="width: 200px;" dropdown
      #dropdown="bs-dropdown">
      <button style="width: 200px; display: flex; padding: 10px; justify-content: space-between; align-items: center;"
        id="button-animated" dropdownToggle type="button" class="btn btn-free dropdown-toggle"
        aria-controls="dropdown-animated" joyrideStep="step1" [text]="'TRAINING.START' | translate"
        [stepPosition]="'right'" [prevTemplate]="prevButton" [nextTemplate]="nextButton" [doneTemplate]="doneButton">
        {{ "GENERAL.MENU_1" | translate }}
      </button>
      <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-animated">
        <li role="menuitem" *ngFor="let item of componentsOptions">
          <button class="btn comp-start text-start" (click)="onAnimationSelected(item)" accordion-heading type="button">
            {{item.content.name | translate}}
          </button>
        </li>
        <li role="menuitem">
          <accordion [isAnimated]="true" [closeOthers]=true>
            <accordion-group
              *ngFor="let item of menuOois trackBy: trackByMenu; let i = index;"
              [joyrideStep]="accordionStep(i+1)" [text]="accordionStep(i+1,true) | translate" [stepPosition]="'right'" [prevTemplate]="prevButton" [nextTemplate]="nextButton" [doneTemplate]="doneButton">

              <button class="btn comp-lvl-1" accordion-heading type="button">
                {{item.content.getName(this.translation.currentLang)}}
              </button>
              <div class="part-header">
                <button type="button" class="d-inline-flex align-items-center btn btn-link small-icon-btn" (click)="item.content.toggle()">
                  <i [hidden]="!item.content.isHidden" class="siemens-hide"></i>
                  <i [hidden]="item.content.isHidden" class="siemens-show"></i></button>
                <p class="nobreak-p" (click)="onItemSelected(item.content)">
                  {{item.content.getName(this.translation.currentLang)}}
                </p>
                <button type="button" *ngIf="hasScrews(item.content)" class="btn btn-link"
                  (click)="onScrewClicked(item.content)">
                  <i-bs name="screwdriver"></i-bs>
                </button>
              </div>
              <ul *ngIf="item.content.children.length > 0">
                <li *ngFor="let ooi of item.content.children trackBy: trackById" class="no-li-style d-inline-flex">
                  <button type="button" class="d-inline-flex align-items-center btn btn-link small-icon-btn" (click)="ooi.toggle()">
                    <i [hidden]="!ooi.isHidden" class="siemens-hide"></i>
                    <i [hidden]="ooi.isHidden" class="siemens-show"></i></button>
                  <a (click)="onItemSelected(ooi)"
                    href="#">{{ooi.getName(this.translation.currentLang)}}</a></li>
              </ul>
            </accordion-group>
          </accordion>
        </li>

      </ul>
    </div>

    <form *ngIf="hasVariants" [formGroup]="form" class="variants-menu">
      <div class="row">
        <div class="col-12">
          <si-form-group label="Variants">
            <si-dropdown formControlName="objectWithT" inputId="objectWithT" [dropdownOptions]="variantOptions"
              [multipleSelect]="false" [valueProvider]="objectValueProvider" [keyProvider]="objectKeyProvider"
              (optionSelected)="onVariantSelected($event)">
              <ng-container *siDropdownOption="let value = value">
                {{value?.content}}
              </ng-container>
            </si-dropdown>
          </si-form-group>
        </div>
      </div>
    </form>

    <div *ngIf="showExplored" style="margin-left:25px; font-weight: bold; display: flex; align-items: center;">
      Explored: {{this.modelService.getOOIExplored()}} / {{this.modelService.getOOICount()}}
    </div>

    <div *ngIf="animationOptions.length > 0" class="btn-group animation-menu" style="width: 200px;" dropdown>
      <button style="width: 200px; display: flex; padding: 10px; justify-content: space-between; align-items: center;"
        id="button-animated" dropdownToggle type="button" class="btn btn-free dropdown-toggle"
        aria-controls="dropdown-animated">
        {{ "GENERAL.MENU_2" | translate }}
      </button>
      <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-wide" role="menu" aria-labelledby="button-animated">
        <li role="menuitem" *ngFor="let item of animationOptions">
          <a class="btn btn-small" (click)="onAnimationSelected(item)" style="text-align: left;"
            href="#">{{item.content.name | translate}}</a>
        </li>
      </ul>
    </div>

    <div *ngIf="trainingsOptions.length > 0" class="btn-group animation-menu" style="width: 200px;" dropdown>
      <button style="width: 200px; display: flex; padding: 10px; justify-content: space-between; align-items: center;"
        id="button-training" dropdownToggle type="button" class="btn btn-free dropdown-toggle"
        aria-controls="dropdown-training">
        {{ "GENERAL.MENU_3" | translate }}
      </button>
      <ul id="dropdown-training" *dropdownMenu class="dropdown-menu dropdown-wide" role="menu" aria-labelledby="button-training">
        <li role="menuitem" *ngFor="let item of trainingsOptions">
          <a class="btn btn-small" (click)="onTrainingSelected(item)" style="text-align: left;"
            href="#">{{item.content.name | translate}}</a>
        </li>
      </ul>
    </div>

    <div *ngIf="animationOptions.length > 0" id="animation-panel">
      <i-bs width="32px" height="32px" name="stopwatch"></i-bs>
      <!--div class="big-icon">
      </div-->
      <input #speedSlider class="slider" (input)="setAnimationSpeed(speedSlider.value)" [(ngModel)]="animationSpeed" min="0.0"
        step="0.25" max="5" value="2" type="range" id="animation-slider" joyrideStep="step6"
        [stepContent]="animationTutorial">
      <button type="button" class="si-header-link icon-button flex button-elevation-2" (click)="togglePause()">
        <i *ngIf="!isPause" class="siemens-pause"></i>
        <i *ngIf="isPause" class="siemens-play"></i>
      </button>

    </div>
    <ng-template #animationTutorial>
      {{ "ANIMATION_TUTORIAL" | translate }}<br>
      {{ "SLOW_ANIMATION" | translate }}<br>
      {{ "FAST_ANIMATION" | translate }}<br>
    </ng-template>
  </div>

  <joyrideStep joyrideStep="step7" [title]="'TRANSPARENCY_MODE' | translate" [stepPosition]="'center'"
    [text]="'TRANSPARENCY_MODE_DESCRIPTION' | translate" [prevTemplate]="prevButton" [nextTemplate]="nextButton" [doneTemplate]="doneButton"></joyrideStep>

  <joyrideStep joyrideStep="step9" [stepPosition]="'center'" [stepContent]="tutorial" [prevTemplate]="prevButton" [nextTemplate]="nextButton" [doneTemplate]="doneButton"></joyrideStep>

  <div class="btn-group animation-menu" [insideClick]="true" dropdown>
    <div class="header-right" style="justify-content: end;" joyrideStep="step8" [stepContent]="headerTutorial" [ngClass]="{ 'button-elevation-1' : modelService.isTransparent, 'button-elevation-2': modelService.isTransparent}">
      <button type="button" class="si-header-link icon-button" (click)="toggleTransparent()">
        <i class="siemens-monitor"></i>
      </button>
      <button type="button" class="si-header-link icon-button" (click)="reset()">
        <i class="siemens-reset"></i>
      </button>
      <button type="button" class="si-header-link icon-button" (click)="showTutorial()">
        <i class="siemens-question"></i>
      </button>
      <button id="icons" dropdownToggle type="button" class="si-header-link icon-button" aria-controls="dropdown-icons">
        <i class="siemens-options"></i>
      </button>
      <ul id="dropdown-icons" *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-settings" role="menu" aria-labelledby="button-icons">
        <li style="padding: 5px;">
          <a class="nav-link" (click)="openModal(options)">
            <span><i class="siemens-settings"></i> {{ "GENERAL.SETTINGS" | translate }}</span>
          </a>
        </li>
        <!--
        <li style="padding: 5px;">
          <a class="nav-link" (click)="showTutorial()">
            <span><i class="siemens-question"></i> {{ "GENERAL.HELP" | translate }}</span>
          </a>
        </li>
        -->
        <li style="padding: 5px;">
          <a class="nav-link" (click)="onGlossarClick(glossary)">
            <span><i class="siemens-document"></i> {{ "GENERAL.GLOSSARY" | translate }} </span>
          </a>
        </li>
        <li style="padding: 5px;">
          <a class="nav-link">
            <span><i class="siemens-email"></i> {{ "GENERAL.CONTACT" | translate }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <ng-template #headerTutorial>
    {{ "HEADER_TUTORIAL" | translate }}<br>
    <span><i class="siemens-monitor" style="font-size: 16px"></i> {{ "TRANSPARENCY_MODE" | translate }}</span><br>
    <span><i class="siemens-reset" style="font-size: 16px"></i> {{ "RESET_VIEW" | translate }}</span><br>
    <span><i class="siemens-question" style="font-size: 16px"></i> {{ "GENERAL.HELP" | translate }}</span><br>
    <span><i class="siemens-options" style="font-size: 16px"></i> {{ "GENERAL.CONTEXT" | translate }}</span><br>
    <span><i class="siemens-settings" style="font-size: 16px"></i> {{ "GENERAL.SETTINGS" | translate }}</span><br>
    <span><i class="siemens-document" style="font-size: 16px"></i> {{ "GENERAL.GLOSSARY" | translate }}</span><br>
    <span><i class="siemens-email" style="font-size: 16px"></i> {{ "GENERAL.CONTACT" | translate }}</span><br>
  </ng-template>

  <ng-template #tutorial>
    <div id="outer-container">
      <div class="column-wrapper" style="align-items: center;">
        <div class="top-section" style="padding: 5px; text-align: center">
          {{ "ROTATE_CAMERA" | translate }}
        </div>
        <div><img src="../../../assets/images/component.png" width="266px" height="266px" /></div>
      </div>
      <div class="column-wrapper" style="align-items: center;">
        <div class="top-section" style="padding: 5px">
          {{ "MOVE_CAMERA" | translate }}
        </div>
        <div><img src="../../../assets/images/component2.png" width="266px" height="266px" /></div>
      </div>
      <div class="column-wrapper" style="align-items: center;">
        <div class="top-section" style="padding: 5px">
          {{ "ZOOM" | translate }}
        </div>
        <div><img src="../../../assets/images/component3.png" width="266px" height="266px" /></div>
      </div>
    </div>
  </ng-template>

</ng-container>

</div>

<!-- exit screwmod button -->
<div class="center-button" *ngIf="this.screwMode">
  <button type="button" class="btn btn-link icon-button button-elevation-1" (click)="exitScrewMode()">
    <i class="siemens-reset"></i>
  </button>
</div>


<ng-template #options>
  <div class="modal-header">
    <h4 class="modal-title">{{ "GENERAL.OPTIONS" | translate }}</h4>
  </div>
  <div class="modal-body">
    <p>Version: {{version}}</p>
    <form [formGroup]="form" class="settings">

      <!--

      <div class="form-check">
        <input id="cb-disabled" class="form-check-input" type="checkbox" [checked]="highlightAnimated"
          (change)="setHighlightAnimated()">
        <label for="cb-disabled" class="form-check-label">
          {{ "SETTINGS.HIGHLIGHT_ANIMATED" | translate }}
        </label>
      </div>
      <br>
      <div class="form-check">
        <input id="cb-disabled" class="form-check-input" type="checkbox" [checked]="isShowDebug"
          (change)="setDebugMode()">
        <label for="cb-disabled" class="form-check-label">
          {{ "SETTINGS.ENABLE_3D_DEBUG" | translate }}
        </label>
      </div>
      -->
      <br>

      <si-form-group label="{{ 'SETTINGS.LANGUAGE' | translate }}">
        <si-dropdown inputId="language" formControlName="objectLanguage" inputId="objectLanguage"
          [dropdownOptions]="languages" (optionSelected)="onLanguageSelected($event)">
          <ng-container *siDropdownOption="let value = value">{{"LANGUAGE." + value.toUpperCase() | translate }}
          </ng-container>
        </si-dropdown>
      </si-form-group>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="modalRef.hide()">
      <span class="siemens-close"></span>
      {{ "GENERAL.CLOSE" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #glossary>
  <div class="modal-header">
    <h4 class="modal-title">{{ "GENERAL.GLOSSARY" | translate }}</h4>
    <button class="btn btn-secondary" (click)="modalRef.hide()">
      <span class="siemens-close"></span>
    </button>
  </div>
  <div class="modal-body" style="align-content: baseline">
    <table class="glossar">
      <tr *ngFor="let item of glossory">
        <td>{{item.abbreviation}}</td>
        <td>{{item.meaning}}</td>
      </tr>
    </table>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>
<ng-template #prevButton>
  <button type="button" class="btn btn-secondary btn-sm">
  {{ "GENERAL.PREV" | translate}}
</button>
</ng-template>
<ng-template #nextButton>
  <button type="button" class="btn btn-secondary btn-sm">
    {{ "GENERAL.NEXT" | translate}}
  </button>
</ng-template>
<ng-template #doneButton>
  <button class="btn btn-secondary btn-sm">{{ "GENERAL.DONE" | translate}}</button>
</ng-template>
