import { AbstractMesh, AnimationGroup, TransformNode, Scene } from "@babylonjs/core"
import { Model, AnimationService } from "../models"
import { groupFrameToTimeConversion } from "../utils/utils"
import { Color3 } from "@babylonjs/core/Maths/math.color"
import { AnimationEvent } from "@babylonjs/core/Animations/animationEvent"

export class WeichenAppAnimationService implements AnimationService {
  public scene: Scene
  public blockieren: boolean = false
  public model: Model
  public halter?: AbstractMesh
  public forceIndicator?: AbstractMesh
  public highlightAnimated = true
  public spinnerMeshes : AbstractMesh[] = []
  public constructor(scene: Scene, model: Model) {
    this.scene = scene
    this.model = model
    this.halter = model.helpers.find(x => x.name == "DistanceHolder")
    console.log(this.halter)
    this.forceIndicator = model.helpers.find(x => x.name == "ForceIndicator")
    console.log(this.forceIndicator)
    this.spinnerMeshes = this.getSpinnerMeshes()
  }
  public speedRatio: number = 2

  public playAnimationByIDName(id: string): void {
    throw new Error("Method not implemented.")
  }

  public playAnimationByID(id: number) {
    switch (id) {
      case 0:
        this.playStellvorgangFull(0, 0.5)
        break

      case 1:
        this.playStellvorgangFull(0.5,0)
        break

      case 2:
        this.playStellvorgangFull()
        break

      case 3:
        this.playAuffahren()
        break

      case 4:
        this.playBlockieren()
        break

      case 5:
        this.playEinbau()
        break

      case 6:
        this.playExplosion()
        break
    }
  }


  public playAnimationByName(name: string) {
    if (this.highlightAnimated) {
      this.highlightAnimatedParts(name)
    }
    const animgroup = this.scene.getAnimationGroupByName(name)
    animgroup!.speedRatio = this.speedRatio * this.GetAnimationSpeedRatio(name)
    animgroup!.stop()
    animgroup!.play()
    switch (name) {
      case "Umstellen_f":
        this.StartSpin()
        animgroup!.onAnimationGroupEndObservable.addOnce(() => this.StopSpin())
        break
      case "Umstellen_r":
        this.StartSpin(true)
        animgroup!.onAnimationGroupEndObservable.addOnce(() => this.StopSpin())
        break
      case "Blockieren":
        this.StartSpin()
    }
  }

  public getSpeedFactor(name:string){
    let result:number = 1
    switch(name){
      case "Spinning":
        result = 2
        break
      case "Spinning_chain":
        result = 2
        break
      case "Einbau":
        result = 0.25
        break
      }
    return result
  }

  private getSpinnerMeshes(){
    let result: AbstractMesh[] = []
    let chainMesh = this.scene.meshes.find(x=>x.name == "99000093552_V_C25123-Z15-C4")
    if(chainMesh)
      result.push(chainMesh)
    const spinWheelAnim = this.scene.getAnimationGroupByName("Spinning")
    if(spinWheelAnim){
      let cogs = this.getAnimationGroupMeshes(spinWheelAnim)
      result = result.concat(cogs)
    }

    return result
  }

  private StartSpin(backward: boolean = false) {
    const spinWheelAnim = this.scene.getAnimationGroupByName("Spinning")
    const spinChainAnim = this.scene.getAnimationGroupByName("Spinning_chain")
    if (spinChainAnim && spinWheelAnim) {
      /*if (this.highlightAnimated) {
        this.highlightAnimationGroup(spinChainAnim)
        this.highlightAnimationGroup(spinWheelAnim)
        const chainMesh = this.scene.getMeshByName("99000093552_V_C25123-Z15-C4");
        if (chainMesh)
          this.model.highlightMesh(chainMesh);
      }*/
      let factor = backward ? -1 : 1
      spinWheelAnim.speedRatio = this.speedRatio * 2 * factor
      spinWheelAnim.stop()
      spinChainAnim.stop()
      spinWheelAnim.play(true)
      if (!backward) {
        spinChainAnim.start(true, this.speedRatio * 2)
      } else {
        spinChainAnim.start(true, this.speedRatio * 2 * -1)
      }
    }
  }

  private StopSpin() {
    const spinWheelAnim = this.scene.getAnimationGroupByName("Spinning")
    const spinChainAnim = this.scene.getAnimationGroupByName("Spinning_chain")
    if (spinChainAnim && spinWheelAnim) {
      spinWheelAnim.stop()
      spinChainAnim.stop()
    }
  }

  private playStellvorgangFull(start: number = 0.0, end: number = 1.0) {
    const fullAnimationTime = 20.84
    const eventBreakTime = 10.42

    const animationStart = fullAnimationTime * start
    const animationEnd = fullAnimationTime * end

    if (this.highlightAnimated) {
      this.highlightAnimatedParts("Umstellen_f")
    }
    if (this.forceIndicator)
      this.forceIndicator.visibility = 0
    if (this.halter)
      this.halter.visibility = 0
    const group = this.scene.getAnimationGroupByName("Umstellen_f")
    group!.speedRatio = this.speedRatio
    group!.stop()

    let me = this
    group?.onAnimationGroupEndObservable.addOnce(() => {
      this.StopSpin()
    })
    if (animationStart < eventBreakTime) {
      this.StartSpin()
    } else {
      this.StartSpin(true)
    }
    const firstAnim = group?.targetedAnimations[0]
    const events = firstAnim?.animation.getEvents()
    if (events) { // remove old event to avoid inconsistency when starting animations before old finished
      for (let index = 0; index < (events?.length) ?? 0; index++) {
        firstAnim?.animation.removeEvents(events[index].frame)
      }
    }
    const animEvent = new AnimationEvent(
      groupFrameToTimeConversion(group!, eventBreakTime), // this is based on the keyframe, so it does not matter when the animation starts
      function (x) {
        me.StopSpin()
        me.StartSpin(true)
        console.log(x)
      },
      false)
    firstAnim?.animation.addEvent(animEvent)
    group!.start(false, this.speedRatio, groupFrameToTimeConversion(group!, animationStart), groupFrameToTimeConversion(group!, animationEnd), false)
  }

  private playBlockieren() {
    if (this.highlightAnimated) {
      this.highlightAnimatedParts("Umstellen_f")
    }
    if (this.halter){
      this.halter.setEnabled(true)
      this.halter.visibility = 1
    }
    this.blockieren = true
    const halterAnim = this.scene.getAnimationGroupByName("Blockieren")
    halterAnim!.speedRatio = this.speedRatio
    halterAnim!.stop()
    halterAnim!.play()
    const group = this.scene.getAnimationGroupByName("Umstellen_f")
    group!.stop()
    group!.start(false, this.speedRatio, 0, groupFrameToTimeConversion(group!, 6.68), false)
    this.StartSpin()
  }

  private playAuffahren() {
    if (this.highlightAnimated) {
      this.highlightAnimatedParts("Auffahren")
    }
    if (this.forceIndicator){
      this.forceIndicator.setEnabled(true)
      this.forceIndicator.visibility = 1
    }

    const group = this.scene.getAnimationGroupByName("Auffahren")
    const firstAnim = group?.targetedAnimations[0]
    let me = this
    const animEvent = new AnimationEvent(
      1.96,
      function (x) {
        me.StartSpin()
        console.log(x)
      },
      false)
    firstAnim?.animation.addEvent(animEvent)
    group!.speedRatio = this.speedRatio
    group!.stop()
    group!.play()
    group?.onAnimationGroupEndObservable.addOnce(() => {
      this.StopSpin()
    })
  }

  private playEinbau() {

    if (this.highlightAnimated) {
      this.highlightAnimatedParts("Einbau")
    }
    if (this.forceIndicator)
      this.forceIndicator.visibility = 0
    if (this.halter)
      this.halter.visibility = 0
    const group = this.scene.getAnimationGroupByName("Einbau")
    const firstAnim = group?.targetedAnimations[0]
    const animEvent = new AnimationEvent(
      1,
      function (x) {
        console.log(x)
        //group?.pause()
        //setTimeout(()=>group?.play(),2000);

      },
      false)
    firstAnim?.animation.addEvent(animEvent)
    group!.speedRatio = this.speedRatio * 0.25
    group!.stop()
    group!.play()
  }

  private playExplosion() {
    if (this.highlightAnimated) {
      this.highlightAnimatedParts("Explosion")
    }
    if (this.forceIndicator)
      this.forceIndicator.visibility = 0
    if (this.halter)
      this.halter.visibility = 0
    const group = this.scene.getAnimationGroupByName("Explosion")
    group!.speedRatio = this.speedRatio
    group!.stop()
    group!.play()
  }

  private getAnimationGroupMeshes(group: AnimationGroup) {
    let animatedMeshes: AbstractMesh[] = []
    group.targetedAnimations.forEach(x => {
      let target = x.target
      if (target instanceof AbstractMesh) {
        animatedMeshes.push(target)
      } else if (target instanceof TransformNode) {
        let meshes = target.getChildMeshes()
        animatedMeshes = animatedMeshes.concat(meshes)
      }
    })
    return animatedMeshes
  }

  private highlightAnimationGroup(group: AnimationGroup) {
    let animatedMeshes: AbstractMesh[] = []
    group.targetedAnimations.forEach(x => {
      let target = x.target
      if (target instanceof AbstractMesh) {
        animatedMeshes.push(target)
      } else if (target instanceof TransformNode) {
        let meshes = target.getChildMeshes()
        animatedMeshes = animatedMeshes.concat(meshes)
      }
    })
    animatedMeshes.forEach(x => {
      this.model.highlightMesh(x)
    })
  }

  private GetAnimationSpeedRatio(name: string) {
    let speed = 1
    switch (name) {
      case "Einbau":
        speed = 0.5
        break
    }
    return speed
  }

  private highlightAnimatedParts(animationName: string) {
    this.model.toggleTransparent(true)
    this.scene.animationGroups.forEach(x => {
      x.reset()
      x.stop()
    })

    let animatedMeshes: AbstractMesh[] = []
    let currentAnimationGroup = this.scene.getAnimationGroupByName(animationName)
    if (currentAnimationGroup) {
      //this.ssao.totalStrength = 0
      currentAnimationGroup.targetedAnimations.forEach(x => {
        let target = x.target
        if (target instanceof AbstractMesh) {
          animatedMeshes.push(target)
        }
        let meshes = target.getChildMeshes()
        animatedMeshes = animatedMeshes.concat(meshes)
      })
    }
    console.log(animationName)
    let spinnerAnims = ["Umstellen_f","Umstellen_r","Blockieren","Auffahren"]
    if(spinnerAnims.includes(animationName)){
      animatedMeshes = animatedMeshes.concat(this.getSpinnerMeshes())
      console.log(this.getSpinnerMeshes())
    }

    this.scene.meshes.forEach(x => {
      if (animatedMeshes.includes(x)) {
        x.visibility = 1
        x.overlayColor = new Color3(0, 1, 1)
        x.overlayAlpha = 0.1
        x.renderOverlay = true
      }
    })
  }
}
