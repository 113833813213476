import { Injectable } from "@angular/core"
import { AnimationService, Indexed, Model, Ooi } from "../models"
import { BabylonEngineService } from "./babylon-engine.service"
import { WeichenAppAnimationService } from "./weichenapp-animation.service"

import appinfoJson from "../../assets/switchapp.json"
import { BehaviorSubject } from "rxjs"
import { XRManagerService } from "./xrmanager.service"
import { ScrewInfoService } from "./screw-info.service"
import { AssetContainer, Node } from "@babylonjs/core"

@Injectable({
  providedIn: "root"
})
export class ModelManagementService {
  private myModel?: Model
  #animationService?: AnimationService
  public currentVariation = "ELS710"
  public menuOois: Indexed<Ooi>[] = [] // read from CSV
  public modelSub = new BehaviorSubject<Model | undefined>(undefined)
  private modelAssetContainer?: AssetContainer

  public get animationService() {
    return this.#animationService
  }


  public constructor(
    private bsEngineService: BabylonEngineService,
    private xrManager: XRManagerService,
    private screwInfoService: ScrewInfoService
  ) {
    this.bsEngineService.speedRatio$.subscribe(speed => {

      if (this.#animationService != null) {
         this.modelAssetContainer?.animationGroups.forEach(x=>{
          if (this.#animationService != null) {
              x.speedRatio = speed * this.#animationService.getSpeedFactor(x.name) ?? 1
          }
      })
      }


    })
   }

  public async loadModel(model: string, variants: Record<string, string>) {
    this.modelAssetContainer = await this.bsEngineService.loadAssets(model)

    this.myModel = new Model(this.modelAssetContainer, variants)
    this.#animationService = new WeichenAppAnimationService(this.bsEngineService.scene, this.myModel)
    this.modelAssetContainer?.addAllToScene()

    // TODO: Needs to go
    const screwsAssetContainer = await this.bsEngineService.loadAssets(appinfoJson.screws)

    if (this.myModel) {
      this.screwInfoService.init(this.myModel, screwsAssetContainer, appinfoJson.screwMapping)
    }
    screwsAssetContainer.addAllToScene()

  }

  public async activateVariation(variation: string) {
    if (this.myModel == null) { return }
    this.currentVariation = variation
    this.menuOois = await this.myModel.showVariantByName("ELS710", this.getMappingPath(variation))
    this.bsEngineService.addMeshesToHover(this.myModel.oois)
    this.xrManager.setMenuOoi(this.menuOois)

    // notify model update
    this.modelSub.next(this.myModel)
  }

  public getMappingPath(variant?: string) {
    if (variant && variant != "") {
      console.log(variant)
      let mapping: any = appinfoJson.variantMapping
      let path = mapping[variant] as string
      console.log(path)
      return path
    } else {
      return ""
    }
  }

  public findOOI(node: Node) {
    return this.modelSub.value?.findOOI(node)
  }

  public toggleTransparent(transparent?: boolean) {
    this.modelSub.value?.toggleTransparent(transparent)
  }

  public get isTransparent() {
    return this.modelSub.value?.baseVisibility !== 1 ?? false
  }

  public resetModel() {
    this.modelSub.value?.reset()
  }

  public selectOOI(ooi: Ooi, exclusive?: boolean) {
    this.modelSub.value?.selectOOI(ooi,exclusive)
  }

  public unselectOOI(ooi: Ooi) {
    this.modelSub.value?.unselectOOI(ooi)
  }

  public unselectAllOOI() {
    this.modelSub.value?.unselectAllOOI()
  }

  public getOOIExplored() {
    this.modelSub.value?.getOOIExplored() ?? 0
  }

  public getOOICount() {
    this.modelSub.value?.getOOICount() ?? 0
  }

  public feedback(ooi: Ooi) {
    ooi.feedback()
  }

  public findOoiById(id: string) {
    return this.modelSub.value?.findOoiById(id)
  }

  public playAnimationByName(name: string) {
    this.#animationService?.playAnimationByName(name)
  }
  public playAnimationByID(id: number) {
    this.#animationService?.playAnimationByID(id)
  }

}
