import { Color3 } from "@babylonjs/core/Maths/math.color"
import { Interaction } from "./utils"

export const functionalities: Record<string,Interaction> = {
  "ausfahren_stellschieber": {
    type: "SIMPLE",
    actions: [{
      type: "SHOW_DIALOG",
      text: "ANIMATION.3_1_AUSFAHREN_STELLSCHIEBER.TEXT",
      title: "ANIMATION.3_1_AUSFAHREN_STELLSCHIEBER.TITLE",
      audio: "ANIMATION.3_1_AUSFAHREN_STELLSCHIEBER.AUDIO",
      align: "right",
      interactions: [
        {
          type: "DIALOG_BUTTON_PRESS",
          text: "GENERAL.RESET_VIEW",
          isClosing: false,
          actions: [{
            type: "ARC_CAMERA",
            x: -2.07,
            y: -0.6,
            z: -0.76,
            alpha: 4.7,
            beta: 0.07,
            radius: 2.1,
            time: 1.8
          }]
        },
        {
          type: "DIALOG_BUTTON_PRESS",
          text: "GENERAL.PLAY_ANIMATION",
          actions: [{
            type: "ANIMATION",
            value: 0
          }]
        }],
      onLeaveActions: [{type: "CLEAR_ALL_LABEL"}]
    },
    {
      type: "ARC_CAMERA",
      x: -2.07,
      y: -0.6,
      z: -0.76,
      alpha: 4.7,
      beta: 0.07,
      radius: 2.1,
      time: 1.8
    },
    {
      type: "ANIMATION",
      value: 0,
    },
    {
      type: "HIDE_PART",
      value: "99000518203"
    },
    {
      type: "HIDE_PART",
      value: "99000436070"
    },
    {
      type: "HIDE_PART",
      value: "STANGENABDECK"
    },
    {
      type: "HIGHLIGHT_PART",
      value: "99001224813",
      options: {
        color: new Color3(0, 1, 1),
        alpha: 0.1,
        visibility: 1
      }
    },
    {
      type: "CLEAR_ALL_LABEL"
    },
    {
      type: "LABEL_PART",
      value: "99001224813",
      text: "{{name}} (2)",
      options: {
        length: 150,
        direction: "up"
      }
    },
    {
      type: "LABEL_PART",
      value: "99000093552",
      text: "{{name}} (3)",
      options: {
        length: 150,
        direction: "left"
      }
    },
    {
      type: "LABEL_PART",
      value: "99000062002",
      text: "{{name}} (4)",
      options: {
        length: 150,
        direction: "left"
      }
    },
    {
      type: "LABEL_PART",
      value: "9000072305_-_C25106-A207-C15",
      text: "{{name}} (1) (10)",
      options: {
        length: 90,
        direction: "down"
      }
    }, {
      type: "LABEL_PART",
      value: "99000770487",
      text: "{{name}} (8)",
      options: {
        length: 130,
        direction: "up"
      }
    }, {
      type: "LABEL_PART",
      value: "99000105531",
      text: "{{name}} (6)",
      options: {
        length: 90,
        direction: "up"
      }
    }, {
      type: "LABEL_PART",
      value: "99000080099",
      text: "{{name}} (5)",
      options: {
        length: 100,
        direction: "down"
      }
    }, {
      type: "LABEL_PART",
      value: "99000064869",
      text: "{{name}} (7)",
      options: {
        length: 80,
        direction: "down"
      }
    }, {
      type: "LABEL_PART",
      value: "99000339688",
      text: "{{name}} (9)",
      options: {
        length: 120,
        direction: "up"
      }
    }
  ]
  },
  "einfahren_stellschieber":   {
    type: "SIMPLE",
    actions: [{
      type: "SHOW_DIALOG",
      text: "ANIMATION.3_2_EINFAHREN_STELLSCHIEBER.TEXT",
      title: "ANIMATION.3_2_EINFAHREN_STELLSCHIEBER.TITLE",
      audio: "ANIMATION.3_2_EINFAHREN_STELLSCHIEBER.AUDIO",
      align: "right",
      interactions: [
        {
          type: "DIALOG_BUTTON_PRESS",
          text: "GENERAL.RESET_VIEW",
          isClosing: false,
          actions: [{
            type: "ARC_CAMERA",
            x: -2.07,
            y: -0.6,
            z: -0.76,
            alpha: 4.7,
            beta: 0.07,
            radius: 2.1,
            time: 1.8
          }]
        },
        {
          type: "DIALOG_BUTTON_PRESS",
          text: "GENERAL.PLAY_ANIMATION",
          actions: [{
            type: "ANIMATION",
            value: 1
          }]
        }],
        onLeaveActions: [{type: "CLEAR_ALL_LABEL"}]
    },
      {
        type: "ARC_CAMERA",
        x: -2.07,
        y: -0.6,
        z: -0.76,
        alpha: 4.7,
        beta: 0.07,
        radius: 2.1,
        time: 1.8
      },
      {
        type: "ANIMATION",
        value: 1,
      },
      {
        type: "HIDE_PART",
        value: "99000518203"
      },
      {
        type: "HIDE_PART",
        value: "99000436070"
      },
      {
        type: "HIDE_PART",
        value: "STANGENABDECK"
      },
      {
        type: "HIGHLIGHT_PART",
        value: "99001224813",
        options: {
          color: new Color3(0,1,1),
          alpha: 0.1,
          visibility: 1
        }
      },
      {
        type: "CLEAR_ALL_LABEL"
      },
      {
        type: "LABEL_PART",
        value: "99001224813",
        text: "{{name}} (2)",
        options: {
          length: 150,
          direction: "up"
        }
      },
      {
        type: "LABEL_PART",
        value: "99000093552",
        text: "{{name}} (3)",
        options: {
          length: 150,
          direction: "left"
        }
      },
      {
        type: "LABEL_PART",
        value: "99000062002",
        text: "{{name}} (4)",
        options: {
          length: 150,
          direction: "left"
        }
      },
      {
        type: "LABEL_PART",
        value: "9000072305_-_C25106-A207-C14",
        text: "{{name}} (1) (10)",
        options: {
          length: 90,
          direction: "down"
        }
      },{
        type: "LABEL_PART",
        value: "99000770487",
        text: "{{name}} (8)",
        options: {
          length: 150,
          direction: "up"
        }
      },{
        type: "LABEL_PART",
        value: "99000105531",
        text: "{{name}} (6)",
        options: {
          length: 90,
          direction: "up"
        }
      },{
        type: "LABEL_PART",
        value: "99000080099",
        text: "{{name}} (5)",
        options: {
          length: 100,
          direction: "down"
        }
      },{
        type: "LABEL_PART",
        value: "99000064869",
        text: "{{name}} (7)",
        options: {
          length: 80,
          direction: "down"
        }
      },{
        type: "LABEL_PART",
        value: "99000339688",
        text: "{{name}} (9)",
        options: {
          length: 60,
          direction: "up"
        }
      }
    ]
  },
  "ausfahren_einfahren_stellschieber":  {
    type: "SIMPLE",
    actions: [{
      type: "SHOW_DIALOG",
      text: "ANIMATION.3_3_AUSFAHREN_EINFAHREN_STELLSCHIEBER.TEXT",
      title: "ANIMATION.3_3_AUSFAHREN_EINFAHREN_STELLSCHIEBER.TITLE",
      audio: "ANIMATION.3_3_AUSFAHREN_EINFAHREN_STELLSCHIEBER.AUDIO",
      align: "right",
      interactions: [
        {
          type: "DIALOG_BUTTON_PRESS",
          text: "GENERAL.RESET_VIEW",
          isClosing: false,
          actions: [{type: "ARC_CAMERA", x:-1.16,y:-0.57,z:-1.05,alpha:4.24,beta:0.90,radius:3.72, time: 1.8},]
        },
        {
          type: "DIALOG_BUTTON_PRESS",
          text: "GENERAL.PLAY_ANIMATION",
          actions: [{
            type: "ANIMATION",
            value: 2
          }]
        }],
      onLeaveActions: [{type: "CLEAR_ALL_LABEL"}]
    },
    {type: "ARC_CAMERA", x:-1.16,y:-0.57,z:-1.05,alpha:4.24,beta:0.90,radius:3.72, time: 1.8},
      {
        type: "ANIMATION",
        value: 2,
      },
      {
        type: "HIDE_PART",
        value: "99000518203"
      },
      {
        type: "HIDE_PART",
        value: "99000436070"
      },
      {
        type: "HIDE_PART",
        value: "STANGENABDECK"
      },
      {
        type: "HIGHLIGHT_PART",
        value: "99001224813",
        options: {
          color: new Color3(0,1,1),
          alpha: 0.1,
          visibility: 1
        }
      },
      {
        type: "CLEAR_ALL_LABEL"
      }
    ]
  },
  "verschließen_stellschieber":  {
    type: "SIMPLE",
    actions: [{
      type: "SHOW_DIALOG",
      text: "ANIMATION.3_4_VERSCHLIEßEN_STELLSCHIEBER.TEXT",
      title: "ANIMATION.3_4_VERSCHLIEßEN_STELLSCHIEBER.TITLE",
      audio: "ANIMATION.3_4_VERSCHLIEßEN_STELLSCHIEBER.AUDIO",
      align: "right",
      interactions: [
        {
          type: "DIALOG_BUTTON_PRESS",
          text: "GENERAL.RESET_VIEW",
          isClosing: false,
          actions: [{
            type: "ARC_CAMERA",
            x: -2.07,
            y: -0.6,
            z: -0.76,
            alpha: 4.7,
            beta: 0.07,
            radius: 2.1,
            time: 1.8
          }]
        },
        {
          type: "DIALOG_BUTTON_PRESS",
          text: "GENERAL.PLAY_ANIMATION",
          actions: [{
            type: "ANIMATION",
            value: 0
          }]
        }],
      onLeaveActions: [{type: "CLEAR_ALL_LABEL"}]
    },
    {type: "ARC_CAMERA",x:-1.55,y:-0.43,z:-0.85,alpha:4.17,beta:0.90,radius:2.66, time: 1.8},
      {
        type: "ANIMATION",
        value: 0,
      },
      {
        type: "HIDE_PART",
        value: "99000518203"
      },
      {
        type: "HIDE_PART",
        value: "99000436070"
      },
      {
        type: "HIGHLIGHT_PART",
        value: "99001224813",
        options: {
          color: new Color3(0,1,1),
          alpha: 0.1,
          visibility: 1
        }
      },
      {
        type: "CLEAR_ALL_LABEL"
      },
      {
        type: "LABEL_PART",
        value: "9000072305_-_C25106-A207-C15",
        text: "{{name}} (1)",
        options: {
          length: 200,
          direction: "down"
        }
      },
      {
        type: "LABEL_PART",
        value: "Tounge.001_primitive0",
        text: "{{name}} (2)",
        options: {
          length: 200,
          direction: "left"
        }
      },
      {
        type: "LABEL_PART",
        value: "99000064869",
        text: "{{name}} (3)",
        options: {
          length: 150,
          direction: "left"
        }
      },
      {
        type: "LABEL_PART",
        value: "9000072305_-_C25106-A207-C14",
        text: "{{name}} (4)",
        options: {
          length: 150,
          direction: "left"
        }
      }
    ]
  },
  "uberwachen_stellschieber": {
    type: "SIMPLE",
    actions: [{
      type: "SHOW_DIALOG",
      text: "ANIMATION.3_5_UBERWACHEN_STELLSCHIEBER.TEXT",
      title: "ANIMATION.3_5_UBERWACHEN_STELLSCHIEBER.TITLE",
      audio: "ANIMATION.3_5_UBERWACHEN_STELLSCHIEBER.AUDIO",
      align: "right",
      interactions: [
        {
          type: "DIALOG_BUTTON_PRESS",
          text: "GENERAL.RESET_VIEW",
          isClosing: false,
          actions: [{type: "ARC_CAMERA", x:-2.29,y:-0.60,z:-0.74,alpha:4.52,beta:0.59,radius:1.01, time: 1.8}]
        },
        {
          type: "DIALOG_BUTTON_PRESS",
          text: "GENERAL.PLAY_ANIMATION",
          actions: [{
            type: "ANIMATION",
            value: 0
          },
          {
            type: "HIGHLIGHT_PART",
            value: "99001123024",
            options: {
              color: new Color3(0,1,1),
              alpha: 0.1,
              visibility: 1
            }
          }]
        }],
      onLeaveActions: [{type: "CLEAR_ALL_LABEL"}]
    },

    {type: "ARC_CAMERA", x:-2.29,y:-0.60,z:-0.74,alpha:4.52,beta:0.59,radius:1.01, time: 1.8},
      {
        type: "ANIMATION",
        value: 0,
        delay: 1800
      },
      {
        type: "HIDE_PART",
        value: "99000518203"
      },
      {
        type: "HIDE_PART",
        value: "99000436070"
      },
      {
        type: "HIGHLIGHT_PART",
        value: "99001224813",
        options: {
          color: new Color3(0,1,1),
          alpha: 0.1,
          visibility: 1
        }
      },
      {
        type: "HIGHLIGHT_PART",
        value: "99001123024",
        options: {
          color: new Color3(0,1,1),
          alpha: 0.1,
          visibility: 1
        }
      },
      {
        type: "CLEAR_ALL_LABEL"
      },
      {
        type: "LABEL_PART",
        value: "9000072305_-_C25106-A207-C15",
        text: "{{name}} (1)",
        options: {
          length: 200,
          direction: "down"
        }
      },
      {
        type: "LABEL_PART",
        value: "99001123024",
        text: "{{name}} (2)",
        options: {
          length: 150,
          direction: "right"
        }
      },
      {
        type: "LABEL_PART",
        value: "9000072305_-_C25106-A207-C14",
        text: "{{name}} (3)",
        options: {
          length: 90,
          direction: "down"
        }
      }
    ]
  },
}
